<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs4 text-left pt-12>
        <span class="itemHeading">Cancelled Bookings List </span>
      </v-flex>

      <v-flex
        text-center
        lg3
        style="color: #000; font-size: 20px; font-family: poppinsbold"
        pt-12
      >
        <v-btn
          dark
          depressed
          color="#68D389"
          @click="filterdialog = true"
          value="#68D389"
          hide-details
        >
          <span>FILTERS </span>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm3 md2 xl1 pr-sm-6 align-self-end>
        <a
          target="_blank"
          :href="
            'https://api.mudumalaitigerreserve.com/cancel/getlistpdf?from=' +
            fromDate +
            '&to=' +
            toDate +
            '&programme=' +
            programme +
            '&status=' +
            filter
          "
          class="downlink"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
            "
          >
            Download Pdf</span
          >
        </a>
      </v-flex>
      <v-flex xs6 sm3 md2 xl1 pr-sm-6 align-self-end>
        <download-excel
          :data="
            filter == 'Pending'
              ? json_dataPending
              : filter == 'Initiated by Bank'
              ? json_dataInitiated
              : filter == 'Completed'
              ? json_dataCompleted
              : json_data
          "
          :fields="
            filter == 'Pending'
              ? json_fieldsPending
              : filter == 'Initiated by Bank'
              ? json_fieldsInitiated
              : filter == 'Completed'
              ? json_fieldsCompleted
              : json_fields
          "
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex align-self-end text-right xs12 v-if="booking.length > 0">
            <v-layout wrap justify-end> </v-layout>
          </v-flex>
          <!-- <v-flex v-if="programme" xs12 sm6 md3 lg3 xl3 pa-5>
            <v-select
              v-model="slotDetail"
              :items="slotItems"
              item-text="startTime"
              item-value="_id"
              item-color="#8d8d8d"
              color="#8d8d8d"
              outlined
              dense
              label="Slots"
            >
              <template v-slot:selection="{ item }">
                <span>
                   {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span>
                 {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
            </v-select>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 sm6 md4 lg4 xl4 text-right>
        <v-select
          v-model="user"
          :items="userName"
          item-text="guestData.name"
          item-value="guestData._id"
          outlined
          dense
          label="User"
        ></v-select>
      </v-flex> -->
    </v-layout>
    <v-layout wrap py-5 v-if="booking.length > 0">
      <template v-for="(item, i) in booking">
        <v-flex xs12 :key="i" pa-4>
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 18px;
                  color: #53a874;
                "
              >
                Ticket #{{ item.cancelData.ticket.ticketNo }}
              </span>
              <v-card outlined>
                <v-layout wrap pa-2>
                  <v-flex
                    xs12
                    align-self-start
                    v-for="(pgm, index) in item.data"
                    :key="index"
                  >
                    <v-layout wrap py-0 justify-start>
                      <v-flex xs12 sm6 md4 align-self-start>
                        <v-img
                          v-if="pgm.paymentItem.programme.photos.length > 0"
                          width="175px"
                          height="113px"
                          :src="mediaURL + pgm.paymentItem.programme.photos[0]"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm6 md8 px-2 pb-2 align-self-start>
                        <p style="font-family: poppinsbold; font-size: 18px">
                          {{ pgm.paymentItem.programme.progName }}
                        </p>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md4>
                            <span class="textField2"> Selected Date</span><br />
                            <span class="getValue">
                              {{ formatDate(pgm.paymentItem.bookingDate) }}
                            </span>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <span class="textField2"> Selected Slot</span><br />
                            <span class="getValue">
                              {{
                                formatTime(pgm.paymentItem.slotDetail.startTime)
                              }}
                              -
                              {{
                                formatTime(pgm.paymentItem.slotDetail.endTime)
                              }}
                            </span>
                          </v-flex>
                          <v-flex
                            xs12
                            sm4
                            v-if="item.cancelData.status == 'Initiated by Bank'"
                          >
                            <v-select
                              v-model="item.cancelData.status"
                              :items="cancelStatus"
                              label="Status"
                              outlined
                              :disabled="item.cancelData.status == 'Completed'"
                              dense
                              @change="
                                askStatus(
                                  item.cancelData._id,
                                  item.cancelData.status
                                )
                              "
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm4 v-else>
                            <v-select
                              v-model="item.cancelData.status"
                              :items="cancelStatusPending"
                              label="Status"
                              outlined
                              :disabled="item.cancelData.status == 'Completed'"
                              dense
                              @change="
                                askStatus(
                                  item.cancelData._id,
                                  item.cancelData.status
                                )
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm12 text-right align-self-end>
                            <span>Date: </span>
                            <span
                              v-if="item.cancelData.status == 'Completed'"
                              >{{
                                formatDate(item.cancelData.compl_date)
                              }}</span
                            >
                            <span
                              v-if="
                                item.cancelData.status == 'Initiated by Bank'
                              "
                              >{{
                                formatDate(item.cancelData.initbybank_date)
                              }}</span
                            >
                            <span v-if="item.cancelData.status == 'Pending'">{{
                              formatDate(item.cancelData.init_date)
                            }}</span>
                          </v-flex>
                          <v-flex xs12 align-self-center>
                            <v-layout wrap>
                              <v-flex xs12 md6 lg3 py-2>
                                <span class="textField2">Transaction ID</span>
                              </v-flex>
                              <v-flex xs12 md6 lg9 py-2>
                                <span class="getValue">{{
                                  item.payment.txnid
                                }}</span>
                              </v-flex>
                              <v-flex xs12 py-2>
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs12 py-2>
                                <span class="textField2"
                                  ><b> Order By </b></span
                                >
                              </v-flex>
                              <v-flex xs12 md6 lg3 py-2>
                                <span class="textField2">User Name</span> <br />
                                <span class="getValue">{{
                                  item.payment.user.details.name
                                }}</span>
                              </v-flex>

                              <v-flex xs12 md6 lg5 py-2>
                                <span class="textField2">User Email</span>
                                <br />
                                <span class="getValue">{{
                                  item.payment.user.details.email
                                }}</span>
                              </v-flex>

                              <v-flex xs12 md6 lg3 py-2>
                                <span class="textField2">User Phone</span>
                                <br />
                                <span class="getValue">{{
                                  item.payment.user.phone
                                }}</span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-2>
                      <v-flex xs12 sm6 md4>
                        <span class="textField2"> Cancel Charge Percentage</span
                        ><br />
                        <span class="getValue">
                          {{ pgm.cancelChargePercentage }} %
                        </span>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <span class="textField2"> Days From Booked Date</span
                        ><br />
                        <span class="getValue" v-if="pgm.daysFromBookingDateString">
                          {{ pgm.daysFromBookingDateString }}
                        </span>
                        <span class="getValue" v-else>
                          {{ pgm.daysFromBookedDate }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <span class="textField2"> Refund Amount</span><br />
                        <span class="getValue">
                          {{ pgm.refundAmount }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-2>
                      <v-flex xs12 md4>
                        <span class="textField2" style="font-size: 14px">
                          Program Total
                        </span>
                      </v-flex>
                      <v-flex xs12 md7 text-right align-self-end>
                        <span class="getValue">
                          {{ pgm.paymentItem.programTotal }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout wrap py-2>
                      <v-flex xs12 md4>
                        <span class="textField2" style="font-size: 14px">
                          Total Amount Paid
                        </span>
                      </v-flex>
                      <v-flex xs12 md7 text-right align-self-end>
                        <span class="getValue">
                          {{ pgm.paymentItem.payment.total }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  justify-center
                  px-2
                  v-if="item.cancelData.entranceTickets.length > 0"
                >
                  <v-flex xs12 text-left py-2>
                    <span class="textField1">Entrance Ticket Details</span>
                  </v-flex>
                  <v-flex
                    xs12
                    v-for="(items, index1) in item.cancelData.entranceTickets"
                    :key="index1"
                  >
                    <v-layout wrap justify-center py-2>
                      <v-flex xs12 sm6 md3 v-if="items.bookingDate">
                        <span class="getValue">{{
                          formatDate(items.bookingDate)
                        }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <span v-if="items.guest" class="getValue">{{
                          items.guest.name
                        }}</span>
                        <span v-else class="getValue">{{ items.vehicle }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <span
                          v-if="items.guest"
                          class="getValue text-capitalize"
                          >{{ items.guest.guestType }}</span
                        >
                        <span v-else class="getValue">{{ items.type }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3 v-if="items.charge">
                        <span class="getValue">{{ items.charge }} INR</span>
                        <span class="getValue">
                          <b> (#{{ items.ticket.ticketNo }})</b>
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 md4 pl-2>
                    <span class="textField2" style="font-size: 14px">
                      Total Amount To Refund
                    </span>
                  </v-flex>
                  <v-flex xs12 md7 text-right align-self-end>
                    <span class="getValue">
                      {{ item.cancelData.refundAmount }} INR
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 px-4 py-2>
                    <span style="font-family: poppinsbold; font-size: 18px"
                      >Payment Details</span
                    >
                  </v-flex>
                  <v-flex xs12 align-self-center v-if="item.payment">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Program Total</th>
                            <th class="text-left">
                              Guest Entrance Ticket Charge
                            </th>
                            <th class="text-left">Vehicle Charge</th>
                            <th class="text-left">Payment Gateway Charge</th>
                            <th class="text-left">Amount Paid</th>
                          </tr>
                        </thead>
                        <tbody class="getValue">
                          <tr style="height: auto">
                            <td>
                              <span v-if="item.payment.programmeTotal">
                                {{ item.payment.programmeTotal }} INR
                              </span>
                              <span v-else>NIL</span>
                              <span> </span>
                            </td>
                            <td>
                              <span
                                v-if="item.payment.guestsEntranceTicketCharge"
                              >
                                {{ item.payment.guestsEntranceTicketCharge }}
                                INR
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span v-if="item.payment.vehicleCharge">
                                {{ item.payment.vehicleCharge }} INR
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span v-if="item.payment.payentgatewayCharge">
                                {{ item.payment.payentgatewayCharge }} INR
                              </span>
                              <span v-else> NIL </span>
                            </td>

                            <td>
                              <span v-if="item.payment.total">
                                {{ item.payment.total }} INR
                              </span>
                              <span v-else> NIL </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 px-4 py-2>
                    <span style="font-family: poppinsbold; font-size: 18px"
                      >Refund Details</span
                    >
                  </v-flex>
                  <v-flex xs12 align-self-center v-if="item.cancelData">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Refund Program Total</th>
                            <th class="text-left">
                              Refund Guest Entrance Ticket Charge
                            </th>
                            <th class="text-left">Refund Vehicle Charge</th>
                            <th class="text-left">
                              Refund Payment Gateway Charge
                            </th>
                            <th class="text-left">Refund Amount</th>
                          </tr>
                        </thead>
                        <tbody class="getValue">
                          <tr style="height: auto">
                            <td>
                              <span
                                v-if="item.cancelData.refundProgrammeAmount"
                              >
                                {{ item.cancelData.refundProgrammeAmount }} INR
                              </span>
                              <span v-else>NIL</span>
                              <span> </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.cancelData.refundGuestEntranceTicketAmout
                                "
                              >
                                {{
                                  item.cancelData.refundGuestEntranceTicketAmout
                                }}
                                INR
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.cancelData
                                    .refundVehicleEntranceTicketAmout
                                "
                              >
                                {{
                                  item.cancelData
                                    .refundVehicleEntranceTicketAmout
                                }}
                                INR
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span> NIL </span>
                            </td>

                            <td>
                              <span v-if="item.cancelData.refundAmount">
                                {{ item.cancelData.refundAmount }} INR
                              </span>
                              <span v-else> NIL </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 px-4 py-2>
                    <span style="font-family: poppinsbold; font-size: 18px"
                      >Account Details</span
                    >
                  </v-flex>
                  <v-flex xs12 align-self-center v-if="item.cancelData">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Account Holder Name</th>
                            <th class="text-left">Account Number</th>
                            <th class="text-left">Bank Name</th>
                            <th class="text-left">Branch Name</th>
                            <th class="text-left">IFSC Code</th>
                            <th class="text-left">Phone Number</th>
                          </tr>
                        </thead>
                        <tbody class="getValue">
                          <tr style="height: auto">
                            <td>
                              <span
                                v-if="
                                  item.cancelData.accountDetails
                                    .account_holder_name
                                "
                              >
                                {{
                                  item.cancelData.accountDetails
                                    .account_holder_name
                                }}
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.cancelData.accountDetails.account_number
                                "
                              >
                                {{
                                  item.cancelData.accountDetails.account_number
                                }}
                              </span>
                              <span v-else>NIL</span>
                            </td>
                            <td>
                              <span
                                v-if="item.cancelData.accountDetails.bankName"
                              >
                                {{ item.cancelData.accountDetails.bankName }}
                              </span>
                              <span v-else>NIL</span>
                            </td>

                            <td>
                              <span
                                v-if="item.cancelData.accountDetails.branchName"
                              >
                                {{ item.cancelData.accountDetails.branchName }}
                              </span>
                              <span v-else> NIL </span>
                            </td>

                            <td>
                              <span
                                v-if="item.cancelData.accountDetails.ifsc_code"
                              >
                                {{ item.cancelData.accountDetails.ifsc_code }}
                              </span>
                              <span v-else> NIL </span>
                            </td>

                            <td>
                              <span v-if="item.cancelData.accountDetails.phone">
                                {{ item.cancelData.accountDetails.phone }}
                              </span>
                              <span v-else> NIL </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-layout wrap v-else justify-center pt-12>
      <v-flex xs12>
        <span class="emptyField">No Cancelled Bookings...</span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="filterdialog" max-width="800px">
      <v-card>
        <v-layout wrap justify-center class="grad">
          <v-flex pt-10
            ><v-layout wrap justify-center>
              <v-flex xs12 sm6 md3 pa-5>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md3 pa-5>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
                <v-select
                  v-model="programme"
                  :items="programs"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  label="Program"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md3 lg3 xl2 pa-5>
                <v-select
                  v-model="filter"
                  :items="filterStatus"
                  item-text="name"
                  item-value="value"
                  item-color="#8d8d8d"
                  color="#8d8d8d"
                  outlined
                  dense
                  label="Status"
                >
                </v-select>
              </v-flex>
              <v-flex xs4 text-center pa-4>
                <v-btn
                  dark
                  depressed
                  color="red"
                  @click="filterdialog = false"
                  value="#68D389"
                  hide-details
                >
                  <span class="poppinssemibold">CLOSE</span>
                </v-btn>
              </v-flex>

              <v-flex xs3 text-left pa-4>
                <v-btn
                  dark
                  depressed
                  color="#68D389"
                  @click="getData2()"
                  value="#68D389"
                  hide-details
                >
                  <span class="poppinssemibold">SUBMIT</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title style="font-family: poppinssemibold; font-size: 16px"
          >Are you sure you want to change current Status?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="getData(), (updateDialog = false)"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="updateStatus(dialogId, dialogStatus)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center v-if="pageCount > 0">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      filterdialog: false,
      currentdate: new Date(),

      booking: [],
      userName: [],
      cancellAll: false,
      user: "",
      guest: [],
      programs: [],
      programme: null,
      disabled: false,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      filter: null,
      filterStatus: [
        { name: "All", value: null },
        { name: "Pending", value: "Pending" },
        { name: "Initiated by Bank", value: "Initiated by Bank" },
        { name: "Completed", value: "Completed" },
      ],
      cancelStatus: ["Initiated by Bank", "Completed"],
      cancelStatusPending: ["Pending", "Initiated by Bank", "Completed"],

      userTypes: [
        { name: "Indian", reference: "indian", value: 0, guest: [] },
        {
          name: "Foreigner",
          reference: "foreigner",
          value: 0,
          guest: [],
        },
        {
          name: "Children",
          reference: "children",
          value: 0,
          guest: [],
        },
      ],
      json_fields: {
        TicketNo: "ticketNo",
        ProgrameName: "programmeName",
        "Cancel Charge %": "cancelChargePercentage",
        "Days from Booking": "daysFromBookedDate",
        // BookingDate: "bookingDate",
        "Pending Date": "init_date",
        "Initiated Date": "initbybank_date",
        "Completed Date": "compl_date",
        // Name: "guest.name",
        // // LastName: "guest.lastName",
        // PhoneNumber: "guest.phoneno",
        // Email: "guest.email",
        // DateofBirth: "guest.dob",
        // Gender: "guest.gender",
        // GuestType: "guest.guestType",
        // "Charge (INR)": "charge",
        // SlotAvailable: "slotDetail.availableNo",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
        // "Refund Amount(INR)":"refundAmount",
        "Total Refund Amount(INR)": "cancelData.refundAmount",
        "Transaction Amount(INR)": "payment.transactionAmount",
      },
      json_fieldsPending: {
        TicketNo: "ticketNo",
        ProgrameName: "programmeName",
        "Cancel Charge %": "cancelChargePercentage",
        "Days from Booking": "daysFromBookedDate",
        // BookingDate: "bookingDate",
        "Pending Date": "init_date",
        // Name: "guest.name",
        // // LastName: "guest.lastName",
        // PhoneNumber: "guest.phoneno",
        // Email: "guest.email",
        // DateofBirth: "guest.dob",
        // Gender: "guest.gender",
        // GuestType: "guest.guestType",
        // "Charge (INR)": "charge",
        // SlotAvailable: "slotDetail.availableNo",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
        // "Refund Amount(INR)":"refundAmount",
        "Total Refund Amount(INR)": "cancelData.refundAmount",
        "Transaction Amount(INR)": "payment.transactionAmount",
      },
      json_fieldsCompleted: {
        TicketNo: "ticketNo",
        ProgrameName: "programmeName",
        "Cancel Charge %": "cancelChargePercentage",
        "Days from Booking": "daysFromBookedDate",
        // BookingDate: "bookingDate",
        "Pending Date": "init_date",
        "Initiated Date": "initbybank_date",
        "Completed Date": "compl_date",
        // Name: "guest.name",
        // // LastName: "guest.lastName",
        // PhoneNumber: "guest.phoneno",
        // Email: "guest.email",
        // DateofBirth: "guest.dob",
        // Gender: "guest.gender",
        // GuestType: "guest.guestType",
        // "Charge (INR)": "charge",
        // SlotAvailable: "slotDetail.availableNo",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
        // "Refund Amount(INR)":"refundAmount",
        "Total Refund Amount(INR)": "cancelData.refundAmount",
        "Transaction Amount(INR)": "payment.transactionAmount",
      },

      json_fieldsInitiated: {
        TicketNo: "ticketNo",
        ProgrameName: "programmeName",
        "Cancel Charge %": "cancelChargePercentage",
        "Days from Booking": "daysFromBookedDate",
        // BookingDate: "bookingDate",
        "Pending Date": "init_date",
        "Initiated Date": "initbybank_date",
        // Name: "guest.name",
        // // LastName: "guest.lastName",
        // PhoneNumber: "guest.phoneno",
        // Email: "guest.email",
        // DateofBirth: "guest.dob",
        // Gender: "guest.gender",
        // GuestType: "guest.guestType",
        // "Charge (INR)": "charge",
        // SlotAvailable: "slotDetail.availableNo",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
        // "Refund Amount(INR)":"refundAmount",
        "Total Refund Amount(INR)": "cancelData.refundAmount",

        "Transaction Amount(INR)": "payment.transactionAmount",
      },
      json_data: [],
      json_dataPending: [],
      json_dataInitiated: [],
      json_dataCompleted: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      updateDialog: false,
      dialogId: null,
      dialogStatus: null,
      currentPage: 1,
      pages: 0,
      pageCount: 0,
    };
  },
  computed: {},
  watch: {
    currentdate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.checkDate();
      // this.getData();
      // this.getExcel();
    },
    // toDate() {
    //   if (this.currentPage > 1) this.currentPage = 1;
    //   // if (this.fromDate)
    //   // this.getData();
    //   // this.getExcel();
    // },

    programme() {
      if (this.currentPage > 1) this.currentPage = 1;
      // this.getData();
      // this.getSlot();
      // this.getExcel();
    },
    cancelType() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
    },
    // filter() {
    //   if (this.currentPage > 1) this.currentPage = 1;
    //   this.getData();
    //   this.getExcel();
    // },
    // user(){
    //   this.getData();
    //   this.getExcel();
    // }
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    // this.selectedFilter=this.dateFilter[0].title
    // this.getData();
    this.getProgramme();
    if (this.$route.query.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 20);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
      this.getData();
      // this.getExcel();
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    // if (this.date) {
    //   this.date.setDate(this.date.getDate() + 1);
    //   this.date = this.date.toISOString().substr(0, 10);
    // }
  },

  methods: {
    checkDate() {
      // alert("lo")
      let newDate = new Date().toISOString().substr(0, 10);
      if (this.fromDate == newDate) {
        const tomorrow = new Date(this.fromDate);
        tomorrow.setDate(tomorrow.getDate() - 20);
        this.fromDate = tomorrow.toISOString().substr(0, 10);
        // this.toDate = this.toDate.toISOString().substr(0, 10);
      }
    },
    getData() {
      // let statusUpdate=null
      this.getExcel();
      this.appLoading = true;
      // if (this.filter == "All") {
      //   this.filter = null;
      // }
      // else
      // statusUpdate=this.filter
      axios({
        method: "GET",
        url: "/cancel/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // cancelType: this.cancelType,
          // user:this.user,
          from: this.fromDate,
          to: this.toDate,

          programme: this.programme,
          status: this.filter,
          count: 12,
          page: this.currentPage,
        },
      })
        .then((response) => {
          
          this.appLoading = false;
          if (response.data.status) {
            this.booking = response.data.data;
            this.pages = response.data.pages;
            this.pageCount = response.data.count;
            
            // var temp = [
            //   {
            //     name: null,
            //     value: null,
            //   },
            // ];
            // temp = response.data.data.cancelData.entranceTickets;
            // for (var i = 0; i < temp.length; i++) {
            //   temp[i].name = temp[i].guest.name;
            //   temp[i].value = temp[i].guest._id;
            // }
            // this.userName=temp;
            // this.userName.unshift({ name: "All Programs", value: null });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    getData2() {
      this.getExcel();
      // let statusUpdate=null
      this.appLoading = true;
      // if (this.filter == "All") {
      //   this.filter = null;
      // }
      // else
      // statusUpdate=this.filter
      axios({
        method: "GET",
        url: "/cancel/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // cancelType: this.cancelType,
          // user:this.user,
          from: this.fromDate,
          // to: this.currentdate,
          to:this.toDate,

          programme: this.programme,
          status: this.filter,
          count: 12,
          page: this.currentPage,
        },
      })
        .then((response) => {
          
          this.appLoading = false;
          if (response.data.status) {
            this.filterdialog = false;
            this.booking = response.data.data;
            this.pages = response.data.pages;
            this.pageCount = response.data.count;
           
            // var temp = [
            //   {
            //     name: null,
            //     value: null,
            //   },
            // ];
            // temp = response.data.data.cancelData.entranceTickets;
            // for (var i = 0; i < temp.length; i++) {
            //   temp[i].name = temp[i].guest.name;
            //   temp[i].value = temp[i].guest._id;
            // }
            // this.userName=temp;
            // this.userName.unshift({ name: "All Programs", value: null });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    askStatus(id, status) {
      this.updateDialog = true;
      this.dialogId = id;
      this.dialogStatus = status;
      // this.updateStatus(id, status)
      // console.log(this.dialogId,"km",this.dialogStatus)
    },

    updateStatus(id, status) {
      // console.log(id,"dfgkm",status)
      // alert(status);
      this.updateDialog = false;
      axios({
        url: "/cancel/status/update",
        method: "POST",
        data: { id: id, status: status },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.dialogId = null;
            this.dialogStatus = null;
            this.getData();
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUser() {
      // this.appLoading = true;
      axios({
        url: "/user/getlist",
        method: "GET",
        params: { role: "guest" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.userName = response.data.data;
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cancel/getlistexcel",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          programme: this.programme,
          status: this.filter,
        },
      })
        .then((response) => {
          if (response.data.status) {
            for (var i = 0; i < response.data.data.length; i++) {
              response.data.data[i].bookingDate = this.formatDate(
                response.data.data[i].bookingDate
              );
              response.data.data[i].dob = this.formatDate(
                response.data.data[i].dob
              );
            }
            this.excel = response.data.data;
            if (this.filter == "Pending") this.json_dataPending = this.excel;
            else if (this.filter == "Initiated by Bank")
              this.json_dataInitiated = this.excel;
            if (this.filter == "Completed")
              this.json_dataCompleted = this.excel;
            else this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProgramme() {
      // this.appLoading = true;
      axios({
        url: "/programme/getlist",
        method: "GET",
        params: { programmestatus: "started" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].progName;
              temp[i].value = temp[i]._id;
            }
            this.programs = temp;
            this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
